import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import { ConvertToDate, ConvertToDatetime } from "../../controller/keys";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

let account=false;
export default function Seminar(){
    const {id}=useParams();
    const [Item,setItem]=useState();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/serma','text':'ホーム'}]});
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let send={
            'url':'v1/serma/project/'+id+'/seminar',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']['seminars']);
                account=data['payloads']['account'];
                setBCrumb({
                    CurrentText: "セミナー",
                    items: [
                        {
                            index: 1,
                            href: "/",
                            text: "ホーム"
                        },
                        {
                            index:2,
                            href:'/project/'+id,
                            text:data['payloads']['ProjectName']
                        }
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);setVisible(false)});
    },[])
    function doEntry(body){
        setVisible(true)
        let send={
            'url':'v1/serma/project/'+id+'/seminar',
            'method':'POST',
            'body':body
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["セミナーを登録しました。"]});
                window.scroll({top:0})
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert? <AlertBox visible={true} type={Alert['type']} msgs={Alert['msgs']} />:""}
            <Loading visible={Visible} />{Item?<SeminarForm Item={Item} account={account} Submit={doEntry} />:""}
        </div>
    )
}

let items=[];
function SeminarForm(props){
    const [Item,setItem]=useState(props.Item);
    useEffect(()=>{
        items=props.Item;
    },[])
    function AddSeminar(){
        let tr=document.createElement('tr');
        let parent=document.getElementById('SeminarList');
        //parent.appendChild(tr);
        //items.push(undefined);
        items=[...items,undefined];
        setItem(items)
        /*ReactDOM.render(<SeminarList />,tr);
        let child=tr.getElementsByTagName('tr')[0];
        //changeDeadline(child.getElementsByClassName('js-deadline-select')[0])
        child.getElementsByClassName('js-copy')[0].addEventListener('click',doCopy);
        child.getElementsByClassName('js-delete')[0].addEventListener('click',doDelete);
        parent.appendChild(child);
        tr.remove();*/
    }
    function doEntry(){
        let tr=document.getElementById('SeminarList').getElementsByTagName('tr');
        let body=[];
        for(let i=0;i<tr.length;++i){
            let items=SendJson(tr[i].getElementsByClassName('js-data'));
            items['holded']=SendJson(tr[i].getElementsByClassName('js-data-holded'));
            body.push(items);
        }
        props.Submit(body);
    }
    return(
        <div>
            <div className="text-end">
                <button type="button" className="btn btn-success" onClick={AddSeminar}>
                    追加
                </button>
            </div>
            <div className="table-responsive"> 
                <table className="table table-striped table-hober">
                    <thead>
                        <tr>
                            <th>
                                開催日時
                            </th>
                            <th>
                                開催場所
                            </th>
                            <th>
                                申込締め切り
                            </th>
                            <th>
                                {props.account?<>金額<br /></>:""}
                                最大受講者数
                            </th>
                            <th>
                                申し込み人数
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody id="SeminarList">{Item.map((seminar,index)=>
                    <SeminarList Item={seminar} Index={index} account={props.account} />
                        )}
                    </tbody>
                </table>
            </div>
            <button type="button" className="btn btn-primary" onClick={doEntry}>登録</button> 
        </div>
    );
}
function SeminarList(props){
    let seminar;
    if(props.Item===undefined){
        let date=ConvertToDate(new Date(),true);
        seminar={
            "SeminarId": 0,
            "holded": [
                date,
                "10:00",
                "11:00"
            ],
            "location": "ZOOM",
            "deadline": 0,
            "BeforeNumber": 1,
            "BeforeTime": "22:00",
            "DeadlineDatetime": ConvertToDatetime(date),
            "MaxPeople": 10,
            "price": 0,
            ApplyPeople:0
          }
    }else{
        seminar=props.Item;
    }
    const [Deadline,setDeadline]=useState(seminar['deadline']);
    function changeDeadline(e){
        setDeadline(Number(e.target.value));
    }
    return(
        <tr key={props.Index}>
            <td style={{'minWidth':'250px'}}>
                <input type="date" name="0" className="form-control js-data-holded" defaultValue={seminar['holded'][0]} required />
                <div className="input-group">
                    <input type="time" name="1" className="form-control js-data-holded" defaultValue={seminar['holded'][1]} required />
                    <span className="input-group-text">～</span>
                    <input type="time" name="2" className="form-control js-data-holded" defaultValue={seminar['holded'][2]} required />
                </div>
            </td>
            <td style={{'minWidth':'150px'}}>
                <input type="text" name="location" className="form-control js-data" placeholder="開催場所" defaultValue={seminar['location']} required />
            </td>
            <td>
                <div className="row">
                    <div className="col-sm-auto">
                        <select name="deadline" className="form-select js-data js-deadline-select" defaultValue={seminar['deadline']} onChange={changeDeadline} data-type="numeric" required>
                            <option value={0}>絶対日時</option>
                            <option value={1}>相対日時</option>
                        </select>
                    </div>
                    <div className="col-sm-auto js-deadline" style={{display:(Deadline?'none':"block")}}>
                        <input type="datetime-local" name="DeadlineDatetime" className="form-control js-data" defaultValue={ConvertToDatetime(seminar['DeadlineDatetime'])} required />
                    </div>
                    <div className="col-sm-auto js-deadline" style={{display:(Deadline?"block":'none')}}>
                        <div className="input-group">
                            <span className="input-group-text">開催日時の</span>
                            <input type="number" name="BeforeNumber" className="form-control js-data" style={{width:'65px'}} defaultValue={seminar['BeforeNumber']} required />
                            <span className="input-group-text">日前</span>
                            <input type="time" name="BeforeTime" className="form-control js-data" style={{width:'100px'}} defaultValue={seminar['BeforeTime']} required />
                        </div>
                    </div>
                </div>
            </td>
            <td style={{'minWidth':'150px'}}>{props.account?
                <div className="input-group">
                    <input type="number" name="price" className="form-control js-data" placeholder="金額" defaultValue={seminar['price']} required />
                    <span className="input-group-text">円</span>
                </div>:""}
                <div className="input-group">
                    <input type="number" name="MaxPeople" className="form-control js-data" placeholder="最大人数" defaultValue={seminar['MaxPeople']} required />
                    <span className="input-group-text">人</span>
                </div>
            </td>
            <td>
                <span className="js-people">{seminar['ApplyPeople']}</span>人
            </td>
            <td style={{'minWidth':'100px'}}>
                <input type="hidden" name="SeminarId" className="js-data js-seminar-id" value={seminar['SeminarId']} />
                <input type="hidden" name="condition" className="js-data js-condition" defaultValue={1} data-type="numeric" />
                <button type="button" className="btn btn-success d-block js-copy" onClick={doCopy}>コピー</button>
                <button type="button" className="btn btn-danger d-block mt-2 js-delete" onClick={doDelete}>削除</button>
            </td>
        </tr>
    );
}
function doCopy(e){
    let tr=e.target.parentNode.parentNode;
    let NewEle=tr.cloneNode(true);
    NewEle.getElementsByClassName('js-seminar-id')[0].value=0;
    NewEle.getElementsByClassName('js-people')[0].textContent=0;
    //changeDeadline(NewEle.getElementsByClassName('js-deadline-select')[0])
    NewEle.getElementsByClassName('js-copy')[0].addEventListener('click',doCopy);
    NewEle.getElementsByClassName('js-delete')[0].addEventListener('click',doDelete);
    tr.parentNode.insertBefore(NewEle,tr.nextElementSibling);
}
function doDelete(e){
    e.target.parentNode.getElementsByClassName('js-condition')[0].value=0;
    e.target.parentNode.parentNode.style.display="none";
}
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import SendJson from "../../controller/SendJson";

export default function Edit(){
    const {id}=useParams();
    const [BCrumb,setBCrumb]=useState({'CurrentText':'','items':[{'index':1,'href':'/','text':'ホーム'}]});
    const [Alert,setAlert]=useState();
    const [Item,setItem]=useState();
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        if(id!='new'){
            Initialize();
        }else{
            setBCrumb({'CurrentText':'新規決済','items':[{'index':1,'href':'/','text':'ホーム'}]});
            setItem({
                AccountName:"",
                ApiKey:"",
                SecretKey:"",
                projects:[]
            });
            setVisible(false);
        }
    },[])
    function Initialize(){
        setVisible(true)
        let send={
            'url':'v1/serma/account/'+id,
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                setBCrumb({
                    CurrentText: "編集",
                    items: [
                        {
                            index: 1,
                            href: "/",
                            text: "ホーム"
                        },
                    ],
                });
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false)
        }).catch(error=>{console.log(error);setVisible(false)});
    }
    function doEntry(){
        setVisible(true)
        let {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});
            setVisible(false)
            window.scroll({top:0});
            return;
        }else{
            let send;
            if(id!='new'){
                send={
                    'url':'v1/serma/account/'+id,
                    'method':'PUT',
                    'body':body
                }
            }else{
                send={
                    'url':'v1/serma/account',
                    'method':'POST',
                    'body':body
                }
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    if(id!='new'){
                        setAlert({'type':'success','style':true,'msgs':["決済を変更しました。"]});
                    }else{
                        setAlert({'type':'success','style':true,'msgs':["決済を登録しました。"]});
                        setItem();
                    }
                    window.scroll({top:0});
                    setVisible(false);
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{console.log(error);setVisible(false)});
        }
    }
    function doDelete(){
        setVisible(true);
        let send={
            'url':'v1/serma/account/'+id,
            'method':'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','style':true,'msgs':["決済を削除しました。"]});
                setItem();
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{console.log(error);
        setVisible(false)});
    }
    return(
        <div>
            <Crumb CurrentText={BCrumb['CurrentText']} items={BCrumb['items']} />
            {Alert? <AlertBox visible={true} type={Alert['type']} msgs={Alert['msgs']} />:""}
            <Loading visible={Visible} />{Item ?
            <div>
                <p>
                    StripeのAPIキーを入力してください。
                </p>
                <div className="input-group mb-2">
                    <span className="input-group-text">
                        登録名
                    </span>
                    <input type="text" name="AccountName" className="form-control js-data" defaultValue={Item['AccountName']} placeholder="登録名" required />
                </div>
                <div className="input-group my-2">
                    <span className="input-group-text">
                        API公開キー
                    </span>
                    <input type="text" name="ApiKey" className="form-control js-data" defaultValue={Item['ApiKey']} placeholder="API公開キー" required />
                </div>
                <div className="input-group my-2">
                    <span className="input-group-text">
                        API秘密キー
                    </span>
                    <input type="text" name="SecretKey" className="form-control js-data" defaultValue={Item['SecretKey']} placeholder="API秘密キー" required />
                </div>{Item['projects'].length?<div className="mt-3"><AlertBox visible={true} type="warning" msgs={["この決済方法を登録しているプロジェクトがあります。"]} /></div>:
                <div className="mt-2">
                    <button type="button" className="btn btn-primary" onClick={doEntry}>
                        {id!='new'?'変更':'登録'}
                    </button>{id!='new'?
                    <button type="button" className="btn btn-danger ms-2" onClick={doDelete}>
                        削除
                    </button>:""}
                </div>}
            </div>
            :""}
        </div>
    )
}
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 *create Breadcrumb
 *
 * @export
 * @param {*} props={
 * items:[
 * {
 * index:
 * href:
 * text:
 * }
 * CurrentText:
 * ]
 * }
 */
export default function Crumb({CurrentText,items}){
    return(
        <div className="row">
            <nav className="px-3 col-auto" aria-label="breadcrumb">
                <Breadcrumb>
                    {/*props.item.items.map(item=><Breadcrumb.Item href={item.href} className="breadcrumb__wrapper-link" key={item.index}>{item.text}</Breadcrumb.Item>)*/}
                    {items.map(item=><li className="breadcrumb-item" key={item.index}><Link to={item.href} className="text-decoration-none text-dark">{item.text}</Link></li>)}
                    <Breadcrumb.Item active>{CurrentText}</Breadcrumb.Item>
                </Breadcrumb>
            </nav>
        </div>
    );
}
/** 
 * npm install react-bootstrap bootstrap@5.1.3
*/
import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Alert, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Style from "./style.css";
import Converter from "./LiCSSLiveEditorConverter";

let BlockType={
    p:"段落",
    h1:"見出し1",
    h2:"見出し2",
    h3:"見出し3",
    h4:"見出し4",
    h5:"見出し5",
    raw:'HTMLコード'
};
let Modes={
    html:'HTML',
    text:'TEXT',
    markdown:'MarkDown'
}
let AlignList={
    left:'左揃え',
    center:'中央揃え',
    right:'右揃え'
}
let InlineList=[
    {
        name:"fontWeight",
        value:"bold",
        title:"B",
        style:{
            fontWeight:'bold'
        }
    },
    {
        name:"fontStyle",
        value:"italic",
        title:"I",
        style:{
            fontStyle:'italic'
        }
    },
    {
        name:"textDecoration",
        value:"underline",
        title:"U",
        style:{
            textDecoration:'underline'
        }
    },
];
let ColorList=[
    {
        code:"#000",
        name:"黒"
    },
    {
        code:"#f00",
        name:"赤"
    },
    {
        code:"#ff0",
        name:"黄"
    },
    {
        code:"#fff",
        name:"白"
    },
    {
        code:"blue",
        name:"青"
    },
    {
        code:"green",
        name:"緑"
    },
    {
        code:"gray",
        name:"灰"
    },
    {
        code:"orange",
        name:"橙"
    },
    {
        code:"purple",
        name:"紫"
    },
    {
        code:"transparent",
        name:"透明"
    },
];
let FontSize={
    10:'10px',
    12:'12px',
    14:'14px',
    16:'標準',
    18:'18px',
    20:'20px',
    24:'24px',
    28:'28px',
    32:'32px',
    40:'40px',
    48:'48px',
    60:'60px',
    72:'72px',
};
let LinkTargetList=[
    {
        name:"同じタブ",
        target:"_self"
    },
    {
        name:"新規タブ",
        target:"_blank"
    }
];
let parent=document;
let InitId=parent.getElementsByClassName('LiCSSsLiveEditor').length;
let ActiveElement=document;
let Selection;
let BlockIds=[];
export default function LiCSSsLiveEditor({
    defaultValue,
    setContent,
    id=InitId,
}){
    /**
     * states
     */
    //styles
    const [ToolbarBlock,setToolbarBlock]=useState(false);
    const [ToolbarInline,setToolbarInline]=useState(false);
    const [LinkStyle,setLinkStyle]=useState(false);
    const [Styles,setStyles]=useState(false);
    const [InlineLink,setInlineLink]=useState(false);
    //settings
    const [Mode,setMode]=useState(defaultValue['mode']?defaultValue['mode']:"html");
    const [blockType,setBlockType]=useState('p');
    const [Align,setAlign]=useState('left');
    //values
    const [Values,setValues]=useState(defaultValue['blocks'].length?defaultValue['blocks']:[
        {
            id:0,
            type:'h1',
            align:'left',
            text:''
        }
    ]);
    const [Entities,setEntities]=useState(defaultValue['entities']?defaultValue['entities']:{});
    const [Links,setLinks]=useState(defaultValue['links']?defaultValue['links']:{});
    //refs
    const LinkTextInput=useRef();
    const LinkTargetInput=useRef();
    const LinkInput=useRef();

    /**
     * functions
     * 
     */
    /**
     * toolbar
     */
    //blocks
    function changeBlockType(e){
        switch(e.target.name){
            case 'type':
                ActiveElement.dataset.type=e.target.value;
                setBlockType(e.target.value);
                break;
            case 'align':
                ActiveElement.dataset.align=e.target.value;
                setAlign(e.target.value);
                break;
        }
        ActiveElement.focus();
        let index=[].slice.call(parent.getElementsByClassName('LiCSSsLiveEditor__editor-block')).indexOf(ActiveElement);
        let editBlock=Values.concat();
        editBlock[index][e.target.name]=e.target.value;
        setValues(editBlock.concat())
    }
    //mode
    function changeMode(e){
        if(e.target.value=='markdown'){
            alert('MarkDown機能はご利用いただけません');
        }else{
            setMode(e.target.value);
        }
    }
    /** 
     * block
    */
    function focusBlock(e){
        ActiveElement=e.target;
        setToolbarBlock(true);
        setBlockType(e.target.dataset.type);
        setAlign(e.target.dataset.align)
        setStyles(false);
        setInlineLink(false);
    }
    function pressDown(e){
        let index;
        let before;
        let after;
        let id;
        let blockElements;
        switch(e.keyCode){
            //backspace
            case 8:
                blockElements=parent.getElementsByClassName('LiCSSsLiveEditor__editor-block');
                if(e.target.value==""){
                    e.preventDefault();
                    if(blockElements.length<2){
                        e.preventDefault();
                    }else{
                        index=[].slice.call(parent.getElementsByClassName('LiCSSsLiveEditor__editor-block')).indexOf(e.target);
                        before=Values.slice(0,index);
                        deleteEntity(index);
                        deleteLink(index);
                        ++index;
                        after=Values.slice(index);
                        setValues(before.concat(after));
                        index-=2;
                        e.preventDefault();
                        blockElements[index].focus();
                    }
                }
                break;
            //enter
            case 13:
                //if(!e.shiftKey){
                    e.preventDefault();
                    index=[].slice.call(parent.getElementsByClassName('LiCSSsLiveEditor__editor-block')).indexOf(e.target)+1;
                    before=Values.slice(0,index);
                    after=Values.slice(index);
                    id=Math.max(...BlockIds)+1;
                    setValues(before.concat({
                        id:id,
                        type:'p',
                        align:'left',
                        text:""
                    }).concat(after));
                    BlockIds.push(id);
                    setTimeout(()=>{
                        let current=index-1;
                        blockElements=parent.getElementsByClassName('LiCSSsLiveEditor__editor-block');
                        if(blockElements[current].nextElementSibling){
                            blockElements[current].nextElementSibling.focus();
                        }else{
                            blockElements[current].focus();
                        }
                    },100)
                //}
                break;
            //upArrow
            case 38:
                blockElements=parent.getElementsByClassName('LiCSSsLiveEditor__editor-block');
                index=[].slice.call(blockElements).indexOf(e.target);
                if(index){
                    --index;
                    blockElements[index].focus();
                }
                break;
            //downArrow
            case 40:
                blockElements=parent.getElementsByClassName('LiCSSsLiveEditor__editor-block');
                index=[].slice.call(blockElements).indexOf(e.target)+1;
                if(blockElements[index]){
                    blockElements[index].focus();
                }
                break;
            //delete
            case 46:
                if(e.target.selectionStart==e.target.value.length){
                    e.preventDefault();
                    blockElements=parent.getElementsByClassName('LiCSSsLiveEditor__editor-block');
                    index=[].slice.call(blockElements).indexOf(e.target)+1;
                    before=Values.slice(0,index);
                    deleteEntity(index);
                    deleteLink(index);
                    id=index-1;
                    before[id]['text']+=Values[index]['text'];
                    blockElements[id].value=before[id]['text'];
                    ++index;
                    after=Values.slice(index);
                    setValues(before.concat(after));
                }
                break;
        }
    }
    function pressUp(e){
        let index=[].slice.call(parent.getElementsByClassName('LiCSSsLiveEditor__editor-block')).indexOf(ActiveElement);
        let editBlock=Values.concat();
        editBlock[index]['text']=e.target.value;
        setValues(editBlock.concat());
        e.target.style.height=0;
        e.target.style.height=e.target.scrollHeight+'px';
    }
    function selectString(e){
        if(e.target.selectionStart!=e.target.selectionEnd){
            setToolbarInline(true);
        }else{
            setToolbarInline(false);
        }
    }
    function deleteEntity(index){
        let entity=Object.assign(Entities);
        if(entity[index]){
            delete entity[index]
        }
        setEntities(Object.assign(entity));
    }
    function deleteLink(index){
        let link=Object.assign(Links);
        if(link[index]){
            delete link[index]
        }
        setLinks(Object.assign(link));
    }
    /**
     * inline
     */
    function addInline(e){
        let newEntity=Object.assign(Entities);
        if(!newEntity[ActiveElement.dataset.id]){
            newEntity[ActiveElement.dataset.id]=[];
        }
        let key=newEntity[ActiveElement.dataset.id].length;
        let style={};
        style[e.target.name]=e.target.value;
        newEntity[ActiveElement.dataset.id].push({
            key:key,//EntityKey
            id:ActiveElement.dataset.id,//blockid
            from:ActiveElement.selectionStart,
            to:ActiveElement.selectionEnd-1,
            type:'style',
            style:style
        });
        setEntities(Object.assign({},newEntity));
    }
    function clickLink(){
        LinkTextInput.current.value=ActiveElement.value.substr(ActiveElement.selectionStart,ActiveElement.selectionEnd-ActiveElement.selectionStart);
        setLinkStyle(!LinkStyle);
    }
    function addLink(){
        let newLink=Object.assign(Links);
        if(!newLink[ActiveElement.dataset.id]){
            newLink[ActiveElement.dataset.id]=[];
        }
        let key=newLink[ActiveElement.dataset.id].length;
        newLink[ActiveElement.dataset.id].push({
            key:key,//LinkKey
            id:ActiveElement.dataset.id,//blockid
            from:ActiveElement.selectionStart,
            to:ActiveElement.selectionEnd-1,
            type:'link',
            data:{
                link:LinkInput.current.value,
                target:LinkTargetInput.current.value,
            }
        });
        setLinks(Object.assign({},newLink));
        LinkInput.current.value="";
        LinkTargetInput.current.value="_blank";
        setLinkStyle(false);
    }
    //inline list
    function clickInline(){
        setStyles(!Styles);
    }
    function createInlineStyle(entity){
        switch(entity['type']){
            case 'style':
                return(
                    <span style={entity['style']}>
                        {ActiveElement.value.substr(entity['from'],entity['to']-entity['from']+1)}
                    </span>
                );
                break;
            default:
                return;
        }
    }
    function deleteInline(e){
        let newEntity=Object.assign({},Entities);
        newEntity[ActiveElement.dataset.id].splice(e.target.dataset.key,1);
        setEntities(newEntity);
    }
    //link style
    function clickLinkList(){
        setInlineLink(!InlineLink)
    }
    function createLinkList(entity){
        switch(entity['type']){
            case 'link':
                return(
                    <a href={entity['data']['link']} target={entity['data']['target']}>
                        {ActiveElement.value.substr(entity['from'],entity['to']-entity['from']+1)}
                    </a>
                );
                break;
            default:
                return;
        }
    }
    function deleteLinkList(e){
        let newEntity=Object.assign({},Links);
        newEntity[ActiveElement.dataset.id].splice(e.target.dataset.key,1);
        setEntities(Links);
    }
    

    //effects
    useEffect(()=>{
        parent=document.getElementById("LiCSSsLiveEditor-"+id);
        document.addEventListener('click', (e) => {
            if(!e.target.closest('.LiCSSsLiveEditor__editor-allow')){
                setToolbarBlock(false);
                setToolbarInline(false);
                if(!e.target.closest('.LiCSSsLiveEditor__toolbar-inline--modal')){
                    setLinkStyle(false);
                    setStyles(false);
                    setInlineLink(false);
                }
            }
        })
        for(let i=0;i<Values.length;++i){
            BlockIds.push(Values[i]['id']);
        }
    },[]);
    useEffect(()=>{
        setContent({
            timestamp:Date.now(),
            mode:Mode,
            blocks:Values,
            entities:Entities,
            links:Links
        });
    },[Values,Entities,Links,Mode])
    return (
        <div id={"LiCSSsLiveEditor-"+id} className="LiCSSsLiveEditor">
            <div className="LiCSSsLiveEditor__toolbar">
                <div className="LiCSSsLiveEditor__toolbar-block" style={{visibility:ToolbarBlock?'visible':'hidden'}}>{Mode=='html'?
                    <>
                        <Row>
                            <Col sm="auto">
                                <Form.Select name="type" value={blockType} className="LiCSSsLiveEditor__editor-allow" onChange={changeBlockType}>{Object.keys(BlockType).map(block=>
                                    <option key={block} value={block}>{BlockType[block]}</option>)}
                                </Form.Select>
                            </Col>{blockType!='raw'?
                            <Col sm="auto">
                                <Form.Select name="align" value={Align} className="LiCSSsLiveEditor__editor-allow" onChange={changeBlockType}>{Object.keys(AlignList).map(block=>
                                    <option key={block} value={block}>{AlignList[block]}</option>)}
                                </Form.Select>
                            </Col>:""}
                        </Row>{blockType!='raw'?
                        <Row className="mt-2">
                            <Col sm="auto" className='position-relative'>
                                <Button variant='secondary' type="button" className="LiCSSsLiveEditor__editor-allow" onClick={clickInline}>
                                    インラインスタイル
                                </Button>
                                <div className='LiCSSsLiveEditor__editor-allow LiCSSsLiveEditor__toolbar-inline--modal' style={{display:Styles?'block':'none'}}>
                                    {ActiveElement.dataset?(Entities[ActiveElement.dataset.id] && Entities[ActiveElement.dataset.id].length)?
                                    <ul>{Entities[ActiveElement.dataset.id].map((entity,index)=>
                                        <li key={entity['key']}>
                                            {createInlineStyle(entity)}
                                            <a href="javascript:void(0)" className="LiCSSsLiveEditor__editor-allow m-2" data-key={index} onClick={deleteInline}>
                                                削除
                                            </a>
                                        </li>
                                    )}
                                    </ul>:"インラインスタイルなし":"インラインスタイルなし"}
                                </div>
                            </Col>
                            <Col sm="auto" className='position-relative'>
                                <Button variant='secondary' type="button" className="LiCSSsLiveEditor__editor-allow" onClick={clickLinkList}>
                                    インラインリンク
                                </Button>
                                <div className='LiCSSsLiveEditor__editor-allow LiCSSsLiveEditor__toolbar-inline--modal' style={{display:InlineLink?'block':'none'}}>
                                    {ActiveElement.dataset?(Links[ActiveElement.dataset.id] && Links[ActiveElement.dataset.id].length)?
                                    <ul>{Links[ActiveElement.dataset.id].map((entity,index)=>
                                        <li key={entity['key']}>
                                            {createLinkList(entity)}
                                            <a href="javascript:void(0)" className="LiCSSsLiveEditor__editor-allow m-2" data-key={index} onClick={deleteLinkList}>
                                                削除
                                            </a>
                                        </li>
                                    )}
                                    </ul>:"リンクなし":"リンクなし"}
                                </div>
                            </Col>
                        </Row>:""}
                    </>:""}
                </div>
                <div className="LiCSSsLiveEditor__toolbar-inline" style={{visibility:ToolbarInline?'visible':'hidden'}}>{(Mode=='html' && blockType!='raw')?
                    <Row>
                        <Col sm="auto">{InlineList.map(inline=>
                            <Button key={inline['name']} variant="secondary" type="button" name={inline['name']} value={inline['value']} className='mx-1 LiCSSsLiveEditor__editor-allow' style={Object.assign({width:'38px'},inline['style'])} onClick={addInline}>{inline['title']}</Button>
                        )}
                        </Col>
                        <Col sm="auto">
                            <Form.Select value={0} name="fontSize" className="LiCSSsLiveEditor__editor-allow" onChange={addInline}>
                                <option label='文字サイズ' style={{display:'none'}} />{Object.keys(FontSize).map(key=>
                                <option key={key} value={key+'px'} style={{fontSize:key+'px'}}>{FontSize[key]}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select value={0} name="color" className="LiCSSsLiveEditor__editor-allow" onChange={addInline}>
                                <option label='文字色' style={{display:'none'}} />{ColorList.map(color=>
                                <option key={color['code']} value={color['code']} style={{color:color['code']}}>{color['name']}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select value={0} name="backgroundColor" className="LiCSSsLiveEditor__editor-allow" onChange={addInline}>
                                <option label='背景色' style={{display:'none'}} />{ColorList.map(color=>
                                <option key={color['code']} value={color['code']} style={{color:color['code']}}>{color['name']}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto" className='position-relative'>
                            <Button variant='secondary' type="button" className="LiCSSsLiveEditor__editor-allow mt-2" onClick={clickLink}>
                                ハイパーリンクの追加
                            </Button>
                            <div className='LiCSSsLiveEditor__editor-allow LiCSSsLiveEditor__toolbar-inline--modal' style={{display:LinkStyle?'block':'none'}}>
                                <Form.Group className="my-2">
                                    <Form.Label>
                                        表示文字
                                    </Form.Label>
                                    <Form.Control type="text" readOnly ref={LinkTextInput} />
                                </Form.Group>
                                <Form.Group className="my-2">
                                    <Form.Label>
                                        URL
                                    </Form.Label>
                                    <Form.Control type="url" ref={LinkInput} className='LiCSSsLiveEditor__editor-allow' placeholder='https://' />
                                </Form.Group>
                                <Form.Group className="my-2">
                                    <Form.Label>
                                        ターゲット
                                    </Form.Label>
                                    <Form.Select defaultValue={"_blank"} className="LiCSSsLiveEditor__editor-allow" ref={LinkTargetInput}>{LinkTargetList.map(target=>
                                        <option key={target['target']} value={target['target']}>{target['name']}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                                <Button variant="success" type="button" className='LiCSSsLiveEditor__editor-allow' onClick={addLink}>追加</Button>
                            </div>
                        </Col>
                    </Row>:""
                }</div>
                <div className="LiCSSsLiveEditor__toolbar-mode">
                    <Form.Select value={Mode} onChange={changeMode}>{Object.keys(Modes).map(mode=>
                        <option key={mode} value={mode}>{Modes[mode]}</option>)}
                    </Form.Select>
                </div>
            </div>
            <Row className="LiCSSsLiveEditor__editor">
                <Col sm="6" className={"LiCSSsLiveEditor__editor-input LiCSSsLiveEditor__editor-"+id}>{Values.map(block=>
                    <textarea key={block['id']} type="text" id={"LiCSSsLiveEditor-"+id+"__block-"+block['id']} className="LiCSSsLiveEditor__editor-block LiCSSsLiveEditor__editor-allow" defaultValue={block['text']} data-id={block['id']} data-type={block['type']} data-align={block['align']} onFocus={focusBlock} onKeyDown={pressDown} onKeyUp={pressUp} onMouseUp={selectString} onTouchEnd={selectString} placeholder="文字を入力" />
                )}
                </Col>
                <Col sm="6" className="LiCSSsLiveEditor__editor-preview">
                    <Converter mode={Mode} blockElements={{
                        blocks:Values,
                        entities:Entities,
                        links:Links
                    }} />
                    {/*JSON.stringify(Values)}<br />
                    {JSON.stringify(Entities)}<br />
                    {JSON.stringify(Links)*/}<br />
                </Col>
            </Row>
            <div className="LiCSSsLiveEditor__footer">
                <p className="fst-italic">
                    Powered by LiCSSsLiveEditor(Created by <a href="https://licsss.com" target="_blank">LiCSSs</a>)
                </p>
                <p>
                    <a href="https://help.licsss.com/licsssliveeditor/" target="_blank">ヘルプ</a>
                    &nbsp;|&nbsp;
                    <a href="https://licsss.com" target="_blank">LiCSSs</a>
                    &nbsp;&nbsp;&copy;&nbsp;LiCSSs.
                </p>
            </div>
        </div>
    );
}

export function ConvertElements({mode,blockElements}){
    return (
        <>
            <Alert variant='warning'>
                Converterを変更してください!!
            </Alert>
            <Converter mode={mode} blockElements={blockElements} />
        </>
    );
}

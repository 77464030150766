export default function Footer(){
	return(
		<footer className="position-absolute bottom-0 start-0 w-100 bg-dark document__footer" role="contentinfo">
			<div className="position-relative">
				<div className="container">
					<div className="row">
						<div className="col text-center text-light">
							&copy;&nbsp;2021&nbsp;<a href="https://sunny-one-step.com/" className="text-reset text-decoration-none">サニーワンステップ株式会社</a>.
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
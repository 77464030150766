import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRights } from "..";
import Fetch from "../controller/Fetch";
import AlertBox from "../controller/AlertBox";
import Loading from "../controller/Loading";
import CardList from "../controller/CardList";

export default function Home(){
    const [Visible,setVisible]=useState(true);
    const [Project,setProject]=useState([]);
    const [Account,setAccount]=useState([]);
    const [Alert,setAlert]=useState();
    useEffect(()=>{
        let rights=getRights();
        if(rights['serma']){
            let send={
                url:'v1/serma/project',
                method:'GET'
            }
            Fetch(send).then(data=>{
                if(data['result']){
                    let items=[];
                    for(let i=0;i<data['payloads'].length;++i){
                        items.push({
                            'index':i,
                            'href':'/project/'+data['payloads'][i]['ProjectId'],
                            'text':data['payloads'][i]['ProjectName']
                        })
                    }
                    setProject(items);
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{
                setVisible(false);
                console.log(error);
            })
            send['url']='v1/serma/account';
            Fetch(send).then(data=>{
                if(data['result']){
                    let items=[];
                    for(let i=0;i<data['payloads'].length;++i){
                        items.push({
                            'index':i,
                            'href':'/account/'+data['payloads'][i]['AccountId'],
                            'text':data['payloads'][i]['AccountName']
                        })
                    }
                    setAccount(items);
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{
                setVisible(false);
                console.log(error);
            })
        }else{
            setAlert({'type':'danger','style':true,'msgs':["SerMa利用権限がありません。"]});
        }
    },[])
    return (
        <div className="mt-3">
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList title="プロジェクト" items={Project} />
                    <Link to="/project/new/edit">新規プロジェクト</Link>
                </div>
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList title="決済" items={Account} />
                    <Link to="/account/new">新規決済</Link>
                </div>
            </div>
        </div>
    );
}
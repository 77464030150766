let vars={
    "%project%":"プロジェクト名",
    "%TeacherEmail%":"講師メールアドレス",
    "%TeacherName%":"講師名",
    "%line%":"公式LINE追加URL",
    "%thank%":"申し込み後サンキューページURL",
    "%datetime%":"セミナー開催日時",
    "%location%":"開催場所",
    "%ApplyName%":"申込者名",
    "%ApplyEmail%":"申込者メールアドレス",
};
export default function EmailVar(){
    return (
        <div className="table-responsive">
            利用可能変数(メール送信時に適用されます)
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>
                            変数名
                        </th>
                        <th>
                            表示内容
                        </th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(vars).map(va=>
                    <tr key={va}>
                        <td>
                            {va}
                        </td>
                        <td>
                            {vars[va]}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    );
}
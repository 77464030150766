import { Card } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { Link } from 'react-router-dom';
/**
 *create Lists in Card
 *
 * @export
 * @param {*} props.item={
 * title:
 * items:[
 * {
 * index;
 * href:
 * text:
 * }
 * ]
 * }
 */
export default function CardList({title,items}){
    return (
        <Card className="w-100">
            <Card.Header>
                {title}
            </Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    {items.map(item=><Link to={item.href} className="list-group-item list-group-item-action" key={item.index}>{item.text}</Link>)}
                </ListGroup>
            </Card.Body>
        </Card>
    );
}
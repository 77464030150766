import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Footer from './view/common/Footer';
import Header from './view/common/Header';
import Home from './view/Home';
import Apply from './view/project/apply';
import Detail from './view/project/detail';
import Edit from './view/project/edit';
import Seminar from './view/project/seminar';
import AccountEdit from './view/account/edit';

export default function App() {
	return (
		<BrowserRouter basename='/'>
			<Header />
			<main className='document__main' role="main">
				<div className='container h-100'>
					<div className='row h-100'>
						<div className='col-12 h-100'>
							<Routes>
								<Route path='/'>
									<Route index element={<Home />} />
									<Route path='project/:id'>
										<Route index element={<Detail />} />
										<Route path="edit" element={<Edit />} />
										<Route path='apply' element={<Apply />} />
										<Route path='seminar' element={<Seminar />} />
									</Route>
									<Route path='account/:id'>
										<Route index element={<AccountEdit />} />
									</Route>
								</Route>
							</Routes>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
